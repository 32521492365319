import { Api } from ".";
import {
  BlobResponse,
  CalendarEvent,
  SpreadsheetExportType,
  TimeEntry,
  TimeTrackingReportData,
  TimeTrackingViewReportType,
} from "../types";
import Utils from "../utilities/Utils";

export default class TimeEntriesController extends Api {
  static getActiveTimer() {
    return fetch("/api/timer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getEntries(page: number = 1, parameters = {}) {
    return fetch(
      `/api/time_entries?page=${page}&${Utils.encodeQueryData(parameters)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }

  static getView(
    params: object
  ): Promise<{ time_entries: TimeEntry[]; leave: CalendarEvent[] }> {
    return fetch(`/api/time_entries/view?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static create(entry: TimeEntry) {
    return fetch("/api/time_entries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ entry }),
    }).then((res) => res.json());
  }

  static update(entry: TimeEntry) {
    return fetch(`/api/time_entries/${entry.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ entry }),
    }).then((res) => res.json());
  }

  static delete(id: string) {
    return fetch(`/api/time_entries/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static deleteAll(ids: string[]) {
    return fetch(`/api/time_entries/destroy_all`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      body: JSON.stringify({ time_entry_ids: ids }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getSummary(params: Object) {
    return fetch(`/api/time_entries/summary?${Utils.encodeQueryData(params)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getExportForm() {
    return fetch(`/api/time_entries/export_form`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
    }).then((res) => res.json());
  }

  static export(queryData: any): Promise<BlobResponse> {
    return fetch(
      `/api/time_entries/export?${Utils.encodeQueryData(queryData)}`,
      {
        method: "GET",
        headers: {
          Authorization: this.token,
        },
      }
    ).then((res) => {
      return new Promise((resolve, reject) => {
        res.blob().then((blob) => {
          resolve({
            filename: res.headers
              .get("content-disposition")
              .match(/filename="(.+)"/)[1],
            blob: blob,
          });
        });
      });
    });
  }

  static getForm(id?: string) {
    const params = { id: id };

    return fetch(`/api/time_entries/form?${Utils.encodeQueryData(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getLedgerItemEntries(data: any) {
    return fetch(`/api/time_entries?${Utils.encodeQueryData(data)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static importEntries(data: any) {
    return fetch(`/api/time_entries/import?${Utils.encodeQueryData(data)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static getTimeEntriesReport(data: {
    start: string;
    end: string;
    type: TimeTrackingViewReportType;
  }): Promise<TimeTrackingReportData> {
    return fetch(`/api/time_entries/report?${Utils.encodeQueryData(data)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static reportExport(data: {
    start: string;
    end: string;
    type: TimeTrackingViewReportType;
    spreadsheet_type: SpreadsheetExportType;
    filters: any;
  }): Promise<BlobResponse> {
    return fetch(
      `/api/time_entries/report_export?${Utils.encodeQueryData(data)}`,
      {
        method: "GET",
        headers: {
          Authorization: this.token,
        },
      }
    ).then((res) => {
      return new Promise((resolve, reject) => {
        res.blob().then((blob) => {
          resolve({
            filename: res.headers
              .get("content-disposition")
              .match(/filename="(.+)"/)[1],
            blob: blob,
          });
        });
      });
    });
  }

  static getRate(entry: TimeEntry): Promise<{ rate: number }> {
    return fetch(`/api/time_entries/rate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ entry: entry }),
    }).then((res) => res.json());
  }
}
